import { createModel } from "@rematch/core"
import { UserProfileState, LoginSuccessPayload } from "./interface"
import produce from "immer"

export const userProfiles = createModel<UserProfileState>({
  state: {
    id: "",
    username: "",
    name: "",
    email: "",
    phone: "",
    picture_url: "",
    address: undefined,
    role: "",
    created_at: 0,
    createdBy: 0,
    enabled: true,
    school: "",
    phone_verified: false,
    email_verified: false
  },
  reducers: {
    loginSuccess: produce(
      (draftState: UserProfileState, payload: LoginSuccessPayload) => {
        console.log(payload);
        
        {
          draftState.id = payload.id
          draftState.username = payload.username
          draftState.name = payload.name
          draftState.phone = payload.phone
          draftState.role = payload.role
          draftState.picture_url = payload.picture_url
          draftState.enabled = payload.enabled
          draftState.email = payload.email
          draftState.email_verified = payload.email_verified
          draftState.phone_verified = payload.phone_verified
          draftState.address = payload.address
          draftState.school = payload.school
          draftState.updated_at = payload.updated_at
        }
      }
    ),
    logout: produce((draftState: UserProfileState) => {
      draftState.id = ""
      draftState.username = ""
      draftState.name = ""
      draftState.phone = ""
      draftState.role = ""
      draftState.picture_url = ""
      draftState.enabled = false
      draftState.email = ""
      draftState.email_verified = false
      draftState.phone_verified = false
    }),
  },
  effects: {},
})
