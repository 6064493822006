import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { moduleRoutes } from "./modules/routes";
import { Loading } from "./components";
import { Provider } from "react-redux";
import { store } from "./store";
import ErrorScreen from "./modules/auth/screens/ErrorScreen";
import { getServiceProxy } from "./services";
import { Spin } from "antd";
import { clearSessionCookie, i18n } from "./core";
import bt_bg from "./static/images/h-under-maintenance-background.jpg";

interface Props {}
interface State {
  loading: boolean;
}

class App extends React.PureComponent<Props, State> {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiService = getServiceProxy(`Bearer ${token}`);
      const userProfile = await apiService.getProfile();

      if (userProfile) {
        store.dispatch.userProfiles.loginSuccess(userProfile);
      }
    } catch (error) {
      clearSessionCookie();
      store.dispatch.userProfiles.logout();
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const MAINTENANCE = false;
    return (
      <Provider store={store}>
        {MAINTENANCE ? (
          <div className="cloudbook__baotri">
            <h1>
              Hệ thống hiện đang bảo trì nâng cấp. <br></br>
              Thời gian bảo trì 30 phút
            </h1>
          </div>
        ) : (
          <React.Suspense fallback={<Loading />}>
            {this.state.loading ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "51px",
                  margin: "31px",
                }}
              >
                <Spin />
              </div>
            ) : (
              <Switch>
                {moduleRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
                <Route path="*" component={ErrorScreen} />
              </Switch>
            )}
          </React.Suspense>
        )}
      </Provider>
    );
  }
}

export default App;
