import React from "react"

export const authRoutes = [
  {
    path: "/",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/HomeScreen")),
  },
  {
    path: "/nhap-ma-sach",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/AccessCodeScreen")),
  },
  {
    path: "/dang-nhap",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/LoginScreen")),
  },
  {
    path: "/dang-ki",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/RegisterScreen")),
  },
  {
    path: "/quen-mat-khau",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/Forgotpassword")),
  },
  {
    path: "/doi-mat-khau",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/ResetPassword")),
  },
  {
    path: "/xac-nhan/email",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/VerifyEmail")),
  },
  {
    path: "/xac-nhan-email/:token",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/CheckVerifyCode")),
  },
  {
    path: "/xac-nhan/phone",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/VerifyPhone")),
  },
  {
    path: "/doi-mat-khau/:token",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/ChangePassword")),
  },
  {
    path: "/nguoi-dung/tong-quan",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/UserConfigDashboardScreen")),
  },
  {
    path: "/nguoi-dung/thong-tin/",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/UserInformationScreen")),
  },
  {
    path: "/dang-xuat",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/LogoutScreen")),
  },
  // Bao tri he thong 17/06/2021
  {
    path: "/quan-ly/ma-sach",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/AdminAccessCodeScreen")),
  },
  {
    path: "/quan-ly/ma-sach/tao-ma-sach",
    exact: true,
    admin: false,
    component: React.lazy(() =>
      import("./screens/AdminCreateAccessCodeScreen")
    ),
  },
  // {
  //   path: "/quan-ly/nguoi-dung",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminUsersScreen")),
  // },
  // {
  //   path: "/quan-ly/nguoi-dung/tao-nguoi-dung",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminCreateUserScreen")),
  // },
  // {
  //   path: "/quan-ly/nguoi-dung/sua-thong-tin-nguoi-dung",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminEditUserScreen")),
  // },
]
