import React from "react"
import { UserProfileState, Dispatch, RootState, connect } from "../../store"
import { Redirect } from "react-router-dom"

interface UserAuthorizeProps {
  userProfiles: UserProfileState
  logoutUser: () => void
}

const notRequiredLogin = ['/tap-huan']

export const UserAuthorize = (Component: any) => {
  const BaseComponent = (props: UserAuthorizeProps) => {
    const UserAuthorizeComponent = (componentProps: UserAuthorizeProps) => {
      return (
        <div>
          {(props.userProfiles && props.userProfiles.id || notRequiredLogin.includes(window.location.pathname))  ? (
            <Component {...componentProps} />
          ) : (
            <Redirect to={`/dang-nhap?callbackUrl=${window.location.href}`} />
          )}
        </div>
      )
    }

    return <UserAuthorizeComponent {...props} />
  }

  const mapDispatchToProps = (dispatch: Dispatch) => ({})

  const mapStateToProps = (state: RootState) => ({
    userProfiles: state.userProfiles,
  })

  return connect(mapStateToProps, mapDispatchToProps)(BaseComponent)
}
