import { ServiceProxy } from "./service-proxies"
import fetch from "isomorphic-fetch"
import "firebase/auth"
import { config } from "../config"
import { i18n } from "../core"
interface Header {
  key: string
  value: string
}
const getAuthHttp = (idToken: string, content: string) => {
  return {
    fetch: (url: any, option: any): Promise<Response> => {
      option.headers.Authorization = `${idToken}`
      // option.mode='no-cors';
      option.headers['Accept-Language'] = i18n.language;
      option.headers["Access-Control-Allow-Origin"] = "*"
      option.headers["Access-Control-Allow-Credentials"] = "true"
      option.headers["Access-Control-Allow-Headers"] = "Authorization"
      // option.headers['Content-Type'] = content;
      // option.credentials = 'include';
      return fetch(url, option)
    },
  }
}

const setHttpHeader = (header: Header[]) => {
  return {
    fetch: (url: any, option: any): Promise<Response> => {
      header.forEach((element) => {
        option.headers[element.key] = `${element.value}`
      })
      // option.mode='no-cors';
      // option.headers['Accept-Language'] = i18n.language;
      option.headers["Access-Control-Allow-Origin"] = "*"
      // option.headers['Access-Control-Allow-Credentials']= 'true';
      option.headers["Access-Control-Allow-Headers"] = "Authorization"
      option.headers["Content-Type"] = "application/json"
      // option.credentials = 'include';
      return fetch(url, option)
    },
  }
}

const getServiceProxy = (
  idToken = "",
  content = "application/json"
): ServiceProxy => {
  const httpOption = getAuthHttp(idToken, content)
  return new ServiceProxy(config.url.api, httpOption)
}

const getServiceProxyVer2 = (headers: Header[]): ServiceProxy => {
  const httpOption = setHttpHeader(headers)
  return new ServiceProxy(config.url.api, httpOption)
}

export { getServiceProxy, getServiceProxyVer2 }
