export const permissions = Object.freeze({
  auth: {
    USERS: {
      VIEW: "USERS.VIEW",
      CREATE: "USERS.CREATE",
      EDIT: "USERS.EDIT",
    },
    ROLES: {
      VIEW: "ROLES.VIEW",
      CREATE: "ROLES.CREATE",
      EDIT: "ROLES.EDIT",
      DELETE: "ROLES.DELETE",
    },
    ACCESS_CODES: {
      VIEW: "ACCESS_CODES.VIEW",
      CREATE: "ACCESS_CODES.CREATE",
      EDIT: "ACCESS_CODES.EDIT",
      DELETE: "ACCESS_CODES.DELETE",
    },
  },
  books: {
    BOOKS: {
      VIEW: "BOOKS.VIEW",
      CREATE: "BOOKS.CREATE",
      EDIT: "BOOKS.EDIT",
      DELETE: "BOOKS.DELETE",
    },
  },
})
