import React from "react"
import styles from "./Loading.module.css"
import { Spin } from "antd"

interface Props {}
export const Loading = (props: Props) => {
  return (
    <div className={styles.spin}>
      <Spin size={"large"} />
    </div>
  )
}
