import * as React from "react"
import { mapStateToProps } from "./map_state_to_props"
import { mapDispatchToProps } from "./map_dispatch_to_props"
import { RouteChildrenProps } from "react-router"
import { Result, Button } from "antd"
import { AdminLayout } from "../../../../layouts/Admin/AdminLayout"
import styles from "./style.module.css"
import { useTranslation } from "react-i18next"

interface OwnProps {}

type Props = OwnProps &
  RouteChildrenProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const Screen = (props: Props) => {
  const { t } = useTranslation(["common", "auth"])

  const backToHome = () => {
    props.history.push("/")
  }

  return (
    <div>
      <Result
        status="500"
        title="Error"
        subTitle={t("auth:errorHappen")}
        extra={
          <Button onClick={backToHome} type="primary">
            {t("common:backHome")}
          </Button>
        }
      />
    </div>
  )
}
