import React, { useState } from "react"
import "firebase/auth"
import { Redirect } from "react-router"
import { Dispatch, RootState, connect, UserProfileState } from "../../store"

interface AuthorizeProps {
  userProfiles: UserProfileState
}
export const Authorize = (Component: any, permission: string[]) => {
  const BaseComponent = (props: AuthorizeProps) => {
    const AuthorizeComponent = (componentProps: AuthorizeProps) => {
      if (!props.userProfiles || !props.userProfiles.id) {
        return <Redirect to="/dang-nhap" />
      }

      // let isAuthorized = false;
      // if (!permission || permission.length === 0) {
      //   isAuthorized = true;
      // } else if (permission && permission.length && props.userProfiles.permissions) {
      //   const filterPermission = permission.filter((val: string) => {
      //     return props.userProfiles.permissions.indexOf(val) === -1;
      //   });

      //   isAuthorized = filterPermission && filterPermission.length ? false : true;
      // }
      // if (isAuthorized === false) {
      //   return <Redirect to='/error?code=403' />;
      // }

      return <Component {...componentProps} />
    }

    return <AuthorizeComponent {...props} />
  }

  const mapDispatchToProps = (dispatch: Dispatch) => ({})

  const mapStateToProps = (state: RootState) => ({
    userProfiles: state.userProfiles,
  })

  return connect(mapStateToProps, mapDispatchToProps)(BaseComponent)
}
