import { overrideConfig } from "./override.config"

export const config = {
  appName: "",
  firebase: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
  },
  url: {
    main: "",
    api: "",
    shop: "",
  },
  i18n: {
    VN: "vn",
    EN: "en",
    defaultLang: "vn",
  },
  signInOptions: [
    "email",
    // 'phone',
    // 'facebook',
    // 'google',
  ],
  upload: {
    allowImageExt: /\.(gif|jpg|jpeg|tiff|png|JPG|PNG|JPEG|GIF|TIFF)$/,
    allowExcelExt: /\.(csv|xls|xlsm|xlsx|xml|xlsb|xlam)$/,
    shopIntroImageNumber: 10,
    maxFileSize: 10000000,
  },
  googleMaps: {
    apiKey: "AIzaSyBWcb9PYE2yddvlnN53fB76Y1XSfjURHFk",
  },
  cookies: {
    domain: "",
    maxAge: 0,
  },
  emailPostfix: "@petsport.com",
  regex: {
    username: /^[a-z0-9]{6,36}$/,
    password: /^[a-zA-Z0-9._!@#$%^&*]{6,255}$/,
    email: /^[a-z][a-z0-9_\.]{5,40}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/,
    phone: /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*){8,}$/,
  },
  facebook: {
    appId: "1842008296094931",
    appIdLiveChat: "2381173065239933",
  },
  ...overrideConfig,
}
