import { FormikContext } from "formik"
import * as yup from "yup"

interface ValidateFieldInput {
  fieldName: string
  context: FormikContext<any>
  validateSchema: yup.Schema<any>
}

export const validateField = (params: ValidateFieldInput, cb?: any) => {
  try {
    params.validateSchema.validateSyncAt(
      params.fieldName,
      params.context.values
    )
    params.context.setFieldError(params.fieldName, "")
    if (cb) {
      cb()
    }
  } catch (error) {
    params.context.setFieldError(params.fieldName, error.message)
  }
}
