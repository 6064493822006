import createRematchPersist from "@rematch/persist"
import { init, RematchRootState } from "@rematch/core"
import { connect as reduxConnect, ConnectedComponentClass } from "react-redux"
import { models } from "./models"
import storage from "redux-persist/lib/storage"
import { persistStore } from "redux-persist"

const persistPlugin = createRematchPersist({
  whitelist: ["userProfiles"],
  throttle: 5000,
  version: 1,
  storage,
})

export const store = init({
  plugins: [persistPlugin],
  models,
})

export const persistor = persistStore(store)

export type Store = typeof store
export type Dispatch = typeof store.dispatch
export type RootState = RematchRootState<typeof models>

type Connect = (
  mapStateToProps: (state: RootState) => {},
  mapDispatchToProps: (dispatch: Dispatch) => {}
) => (component: any) => ConnectedComponentClass<any, any>

export const connect: Connect = (mapStateToProps, mapDispatchToProps) => (
  component
) => reduxConnect(mapStateToProps, mapDispatchToProps)(component)
