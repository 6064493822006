import { i18n } from "../i18n/i18n"

export const getBookName = (book: string) => {
  switch (book) {
    case "app1": {
      return i18n.t("auth:bookApp1")
    }
    case "app2": {
      return i18n.t("auth:bookApp2")
    }
    case "app3": {
      return i18n.t("auth:bookApp3")
    }
    case "toan1": {
      return i18n.t("auth:bookToan1")
    }
    case "tiengviet1": {
      return i18n.t("auth:bookTiengviet1")
    }
    default: {
      return ""
    }
  }
}
