import { UserProfileState } from "../../store"

export const checkPermission = (
  profile: UserProfileState,
  requiredPermission: string
) => {
  // if (!requiredPermission) {

  // }
  return true
  // return requiredPermission && profile.permissions && profile.permissions.indexOf(requiredPermission) > -1;
}
