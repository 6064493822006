export const overrideConfig = {
  appName: "pharos",
  firebase: {
    apiKey: "AIzaSyDaNVSsb66VTC7olY2VuAiKmO-J5e_PDYM",
    authDomain: "techkids-ac7a7.firebaseapp.com",
    databaseURL: "https://techkids-ac7a7.firebaseio.com",
    projectId: "techkids-ac7a7",
    storageBucket: "techkids-ac7a7.appspot.com",
    messagingSenderId: "809846546437",
  },
  url: {
    main: "https://api.cloudbook.vn/api/v1",
    api: "https://api.cloudbook.vn/api/v1",
    provider: "https://cloudbook.vn",
    upload: "http://localhost:3000",
  },
  cookies: {
    domain: "localhost",
    maxAge: 60 * 60 * 1000,
  },
}
