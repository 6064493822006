import React from "react"

export const booksRoutes = [
  {
    path: "/tong-quan",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/UserDashboardScreen")),
  },
  {
    path: "/huong-dan",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/InstructionScreen")),
  },
  {
    path: "/huong-dan/:detail",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/InstructionDetailScreen")),
  },
  
  {
    path: "/tap-huan",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/UserVideoTraining")),
  },
  // {
  //   path: "",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/Maintenance")),
  // },

  // Route admin below
  {
    path: "/quan-ly/sach",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/AdminBooksScreen")),
  },
  {
    path: "/quan-ly/sach/tao-sach",
    exact: true,
    admin: false,
    component: React.lazy(() => import("./screens/AdminCreateBookScreen")),
  },
  // {
  //   path: "/quan-ly/sach-goi-y",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminReferenceBookScreen")),
  // },
  // {
  //   path: "/quan-ly/sach-goi-y/tao-sach",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() =>
  //     import("./screens/AdminCreateReferenceBookScreen")
  //   ),
  // },
  // {
  //   path: "/quan-ly/sach-goi-y/sua-thong-tin-sach",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() =>
  //     import("./screens/AdminEditReferenceBookScreen")
  //   ),
  // },
  // {
  //   path: "/quan-ly/sach/sua-thong-tin-sach",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminCreateBookScreen")),
  // },
  // {
  //   path: "/quan-ly/tong-quan",
  //   exact: true,
  //   admin: false,
  //   component: React.lazy(() => import("./screens/AdminDashboardScreen")),
  // },
]
