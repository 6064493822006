export const getValue = (data: any, defaultValue: any, paths: (string | number)[]): any => {
  try {
    if (data === undefined || data === null) {
      return defaultValue;
    }

    let value = data;

    paths.map((p) => {
      value = value[p];

      if (p === 'accessCode') {
        value = value.replace(/-/gi,'').match(/.{1,4}/g).join('-');
      }

      return p;
    });
    if (!value) {
      return defaultValue;
    }
    return value;
  } catch (e) {
    return defaultValue;
  }
};
