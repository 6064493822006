import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { config } from "../../config"

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng: config.i18n.defaultLang,
    fallbackLng: config.i18n.defaultLang,
    debug: true,
    defaultNS: "common",
    interpolation: {
      escapeValue: true,
    },
  })

export { i18n }
